import React from "react";
import {
  PageLayout,
  Background,
  Content,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class AboutUsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, allFishermanBusinessLocation } = data;

    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"} />
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={"restaurant"}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      aboutMarkdown
      reviews {
        author
        text
        link
      }
      _id
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
